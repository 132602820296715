<template>
  <li
    v-if="isShowThisPay"
    :key="item.code"
    v-expose="{
      id: calcSaIdExposePayment,
      data: paymentItemAnasysis
    }"
    :class="[
      'j-payment-item mcheo-payment__item j-' + item.code + '-method',
      { fold: isShowViewMore && idx >= checkout.results.folded_position }
    ]"
    :data-method="item.code"
    :aria-hidden="![6, 7].includes(+item.payment_type)"
    :role="[6, 7].includes(+item.payment_type) ? 'radio' : 'none'"
    :data-type="item.payment_type"
    @click="
      choosePayment({ id: item.id, code: item.code, item: item, index: idx })
    "
  >
    <HomogenizationPay 
      v-if="isFirstPaymentHomogenizationCode" 
      :is-disabled-payment="isDisabledPayment"
      :banks-config="banksConfig"
      :language="language"
    />
    <template v-else>
      <template v-if="itemCode === 'cod'">
        <!-- cod 展示逻辑 -->
        <PaymentOptionsItemCod
          v-expose="paypalExposeData(item)"
          :item="item"
          :cardValue="cardValue"
          :status="status"
          :isDisabledPayment="isDisabledPayment"
          :payment-display="paymentDisplay"
          :payment-des="paymentDes"
          :payment-icon-prefix="paymentIconPrefix"
          :mall_caculate_info="mall_caculate_info"
          :not-support-cod-reason="notSupportCodReason"
          :onlinePayDiscountInfoValue="onlinePayDiscountInfoValue"
          :banks-config="banksConfig"
          :is-client="isClient"
          :language="language"
          :getPaymentLogoUrl="getPaymentLogoUrl"
          @clickItemCod="
            choosePayment({
              id: item.id,
              code: item.code,
              item: item,
              index: idx
            })
          "
          @handleStartSelectBank="handleStartSelectBank"
          @clickDes="clickDes"
          @codTipExpose="codTipExpose"
          @clickCodTips="clickCodTips"
        />
      </template>
      <template v-else>
        <div
          v-expose="paypalExposeData(item)"
          class="mshe-flexbetween"
        >
          <s-checkbox
            class="payment-item-checkbox"
            :value="cardValue"
            :disabled="item.enabled == 0"
            name="paymentmethod"
            :data-id="item.id"
            :label="item.code"
            :margin-r="`${16 / 75}rem`"
          />
          <div
            class="card-left"
            :class="[!isClient ? 'ssr-se' : '']"
          >
            <img
              class="payment-src"
              :src="transformImg({ img: getPaymentLogoUrl })"
            />
            <span
              class="check-list"
              :class="{
                'check-disabled':
                  item.enabled == 0 ||
                  (itemCode != 'cod' && !paymentDisplay) ||
                  (itemCode == 'cod' && notSupportCodReason)
              }"
            >
              <span
                v-if="item.code === 'afterpay-card' && afterPayVault.title && afterPayVault.title === 'A'"
                class="check-title"
                v-html="language.SHEIN_KEY_PWA_30872"
              ></span>
              <span
                v-else
                class="check-title"
              >{{ getPaymentTitle }}</span>
              <transition name="vue-fade">
                <span
                  v-show="isShowCheckTips"
                  class="check-tips"
                >
                  <em
                    v-if="paymentDes"
                    class="sui_icon_doubt_16px_2 icon-tips"
                    :class="[paymentIconPrefix]"
                    @click.stop="clickDes()"
                  ></em>
  
                  <em
                    v-show="
                      googlePayMethods.includes(item.code) &&
                        !paymentDes &&
                        !googleValid
                    "
                    class="sui_icon_doubt_16px_2 icon-tips"
                    :class="[paymentIconPrefix]"
                    @click.stop="showMaskTips(language.google_pay_invalid)"
                  ></em>
                </span>
              </transition>
            </span>
            <template
              v-if="
                !isDisabledPayment &&
                  banksConfig[item.code] &&
                  banksConfig[item.code].list &&
                  banksConfig[item.code].list.length
              "
            >
              <div class="bank-contain">
                <div
                  v-if="!isShowHomogenizationLogList"
                  class="selected-bank-area"
                >
                  <div
                    class="selected-bank-text"
                    :class="{ 'txt-error': banksConfig[item.code].error }"
                  >
                    {{
                      banksConfig[item.code].bankCode &&
                        banksConfig[item.code].bankCode !== -1
                        ? ''
                        : language.SHEIN_KEY_PWA_15965
                    }}
                  </div>
                  <i
                    class="sui_icon_more_down_16px icon2"
                    :class="[paymentIconPrefix , {'txt-error': banksConfig[item.code].error }]"
                  ></i>
                </div>
                <!-- <p class="text-error" :class="{'animated bounce':banksConfig[item.code].error}" v-show="banksConfig[item.code].error">{{language.SHEIN_KEY_PWA_15965}}</p> -->
              </div>
            </template>
  
            <!-- 0元门槛优惠 -->
            <s-label
              v-if="isShowZeroDiscount"
              type="promo"
              class="payment-discount-zero"
            >
              <template v-if="onlinePayDiscountInfoValue.discountType == 0">
                <template v-if="GB_cssRight">
                  {{
                    language.SHEIN_KEY_PWA_14988 +
                      ' ' +
                      onlinePayDiscountInfoValue.amount.amountWithSymbol
                  }}
                </template>
                <template v-else>
                  {{
                    onlinePayDiscountInfoValue.amount.amountWithSymbol +
                      ' ' +
                      language.SHEIN_KEY_PWA_14988
                  }}
                </template>
              </template>
  
              <template v-else-if="onlinePayDiscountInfoValue.discountType == 1">
                <template v-if="GB_cssRight">
                  {{
                    language.SHEIN_KEY_PWA_14988 +
                      ' ' +
                      onlinePayDiscountInfoValue.percent +
                      '%'
                  }}
                </template>
                <template v-else>
                  {{
                    onlinePayDiscountInfoValue.percent +
                      '% ' +
                      language.SHEIN_KEY_PWA_14988
                  }}
                </template>
              </template>
            </s-label>
  
            <div
              v-show="(isShowTokenPayment || isShowTokenFrontInstallment) && !isPayMethodsDrawer"
              class="left-drawer"
            >
              <template v-if="isShowMoreDiscount && item.enabled == 1 && !isInstallmentIsUseNewCard">
                <span class="more-discount">
                  {{ language.SHEIN_KEY_PWA_30845 }}
                </span>
                <span class="red-point"></span>
              </template>

              <Icon
                name="sui_icon_more_right_16px_2"
                size="16px"
                @click.stop.prevent="handlerTokenList"
              />
            </div>
          </div>
        </div>
      </template>
      <!-- 支付信息前置展示: 7+1 -->
      <div
        v-if="
          ((frontListItemsValue.is_display == 1 && item.enabled == 1) ||
            (['Paytm-UPI', 'cashfree-upi'].indexOf(item.code) > -1 &&
              item.enabled == 1) ||
            (item.enabled && onlinePayDiscountInfoValue) ||
            (item.card_logo_list && item.card_logo_list.length) ||
            showDescriptionTxt2 ||
            (needPayInlinePaymentMethods &&
              needPayInlinePaymentMethods.indexOf(item.code) > -1) ||
            isShowCardBinDiscount ||
            (item.code === 'PayPal-GApaypal' &&
              paypalGaVault &&
              paypalGaVault.valid &&
              paypalGaVault.express) ||
            (item.code === 'afterpay-card' &&
              item.enabled &&
              isShowAfterPayRememberByAbt)) || isShowPayBinCoupons
        "
        v-expose="{
          id: '1-11-1-86',
          data: {
            payment_code: item.code
          }
        }"
        class="payment-footer"
      >
        <!-- 区域:paypal记住卡号逻辑 -->
        <CheckPpgaType
          v-if="
            item.code === 'PayPal-GApaypal' &&
              item.enabled &&
              paypalGaVault &&
              paypalGaVault.valid &&
              paypalGaVault.express &&
              (selectedPaypal || isAutoRenewal)
          "
          :language="language"
          :paypal-ga-vault="paypalGaVault"
          :checked-label="PPGA_CHECK_TYPE.CHECKED"
          :show-bottom-line="status.cardPay == item.code && item.enabled == 1"
          @toggle="handleTogglePpgaType"
        />
        <CheckAfterPayType
          v-if="
            item.code === 'afterpay-card' &&
              item.enabled &&
              afterPayVault &&
              afterPayVault.valid &&
              (selectedAfterpay || isAutoRenewal)
          "
          :language="language"
          :afterPayVault="afterPayVault"
          :checked-label="AFTERPAY_CHECK_TYPE.CHECKED"
          :show-bottom-line="status.cardPay == item.code && item.enabled == 1"
          @toggle="handleToggleAfterPayType"
        />
        <!-- 区域:分期支付信息展示 -->
        <!-- bnpl组件 -->
        <ItemInfoBnpl
          scene="checkout"
          :isSelect="cardValue"
          :frontListItemsValue="frontListItemsValue"
          :options="installmentInfo.bnpl_info"
          :item="item"
          :language="language"
        >
          <!-- 分期文案描述逻辑，提供slot插入能力， -->
          <!-- 需求保持文案展示旧逻辑，故先不处理选中文案逻辑拆分 -->
        </ItemInfoBnpl>
        <!-- 区域:['Paytm-UPI', 'cashfree-upi'] input输入 -->
        <div
          v-if="
            ['Paytm-UPI', 'cashfree-upi'].indexOf(item.code) > -1 &&
              item.enabled == 1
          "
          class="paytm-upi-tips"
          :class="{ 'error-input-item': paymentOption[item.code].showError }"
        >
          <p
            v-show="
              status.cardPay != item.code &&
                panlePaymentOption[item.code].payerAccount !== ''
            "
          >
            {{ panlePaymentOption[item.code].payerAccount }}
          </p>
          <div v-show="status.cardPay == item.code">
            <p>{{ language.SHEIN_KEY_PWA_16988 }}</p>
            <p class="input-item">
              <input
                v-model.trim="panlePaymentOption[item.code].payerAccount"
                type="text"
                name="paytm-upi-tips"
                :placeholder="
                  item.code == 'cashfree-upi'
                    ? language.SHEIN_KEY_PWA_17404
                    : language.SHEIN_KEY_PWA_16985
                "
                maxlength="32"
                @input="checkValidatePaytmUPI(item.code)"
                @blur="checkValidatePaytmUPI(item.code)"
              />
              <i class="iconfont icon-warn-out"></i>
            </p>
            <p class="error-tips">
              {{ language.SHEIN_KEY_PWA_16986 }}
            </p>
          </div>
        </div>

        <div class="all-discount-block">
          <!-- 卡bin优惠券 -->
          <pay-bin-coupons 
            v-if="item.code === 'routepay-card'"
            class="payment-options-item__coupons_txt"
            :class="[{
              'pay-item-token__bin_coupons': isShowTokenPayment
            }]"
            scene="payment-options-item"
            :is-show-token-payment="isShowTokenPayment"
            :card-bin="handlerSelectBin || defaultSelectTokenPayCardBin"
            :is-pay-bin-coupons-add="isPayBinCouponsAdd" 
            :language="language"
          />
    
          <!-- token 卡 bin 优惠 -->
          <div 
            v-if="isShowTokenBinDiscountBlock && !isInstallmentIsUseNewCard"
            :class="[{
              'pay-item-token__bin': isShowTokenPayment
            }]"
          >
            <span class="discount-tips-item">
              {{ tokenBinDiscountDesc }}
            </span>
            <span
              v-if="isHadShowOnlinePayDiscount || isShowRandomDiscount"
            >
              +
            </span>
          </div>
          <!-- 区域:折扣优惠 -->
          <div class="discount-percent_tips discount-percent-sy">
            <div
              v-if="isHadShowOnlinePayDiscount"
              class="discount-tips-item"
            >
              <div
                v-if="onlinePayDiscountInfoMinAmount != 0"
                class="payment-discount-tip"
                :style="{ color: IS_RW && '#FC4070' }"
              >
                <!-- 满减优惠 -->
                <template v-if="onlinePayDiscountInfoValue.discountType == 0">
                  {{
                    template(
                      onlinePayDiscountInfoValue.min.amountWithSymbol,
                      onlinePayDiscountInfoValue.amount.amountWithSymbol,
                      language.SHEIN_KEY_PWA_16207
                    )
                  }}
                </template>
                <!-- 折扣优惠 -->
                <template v-if="onlinePayDiscountInfoValue.discountType == 1">
                  {{
                    template(
                      onlinePayDiscountInfoValue.min.amountWithSymbol,
                      onlinePayDiscountInfoValue.percent + '%OFF',
                      language.SHEIN_KEY_PWA_16207
                    )
                  }}.
                </template>
                <!-- 最大优惠信息 -->
                <template
                  v-if="
                    onlinePayDiscountInfoValue.discountType == 1 &&
                      onlinePayDiscountInfoValue.limit_value &&
                      +onlinePayDiscountInfoValue.limit_value.amount != 0
                  "
                >
                  {{
                    language.SHEIN_KEY_PWA_19681.replace(
                      '{limitValue}',
                      onlinePayDiscountInfoValue.limit_value.amountWithSymbol
                    )
                  }}
                </template>
              </div>
              <div
                v-else
                class="payment-discount-tip"
                :style="{ color: IS_RW && '#FC4070' }"
              >
                <!-- 最大优惠信息 -->
                <template
                  v-if="
                    onlinePayDiscountInfoValue.discountType == 1 &&
                      onlinePayDiscountInfoValue.limit_value &&
                      +onlinePayDiscountInfoValue.limit_value.amount != 0
                  "
                >
                  {{
                    language.SHEIN_KEY_PWA_19681.replace(
                      '{limitValue}',
                      onlinePayDiscountInfoValue.limit_value.amountWithSymbol
                    )
                  }}
                </template>
              </div>
            </div>
    
            <!-- 随机立减 -->
            <div
              v-if="isShowRandomDiscount"
              class="discount-tips-item"
            >
              <div
                class="payment-discount-tip"
                :style="{ color: IS_RW && '#FC4070' }"
              >
                {{ randomDiscountDes }}
              </div>
            </div>
            <!-- 卡bin优惠信息展示 -->
            <template v-if="!isShowTokenPayment && !isShowTokenFrontInstallment">
              <span
                v-if="
                  isShowOnlinePayDiscount &&
                    isShowCardBinDiscount &&
                    cardBinDiscountDesc
                "
              >
                +
              </span>
              <span
                v-if="isShowCardBinDiscount && cardBinDiscountDesc"
                class="discount-tips-item"
              >
                {{ cardBinDiscountDesc }}
                <i
                  class="sui_icon_doubt_16px_2 icon"
                  :class="[paymentIconPrefix]"
                  style="width: 12px"
                  @click.stop="handlePaymentOptionsOper('bin-discount-article')"
                ></i>
              </span>
            </template>
          </div>
        </div>

        <!-- 区域:卡列表展示 -->
        <div
          v-if="item.card_logo_list && item.card_logo_list.length && !isShowTokenPayment"
          class="card-logo"
        >
          <ul>
            <li
              v-for="(img, i) in item.card_logo_list"
              :key="i"
              class="card-logo-item"
            >
              <img :src="transformImg({ img: img })" />
            </li>
          </ul>
        </div>
        <!-- 区域: 支付方式描述desc -->
        <slide-up-down
          class="animated fadeIn"
          :active="status.cardPay == item.code"
          :duration="500"
        >
          <div class="extra-tip">
            <p
              v-if="itemCode == 'cod' && notSupportCodReason"
              class="content"
            >
              {{ notSupportCodReason }}
            </p>
            <p
              v-if="item.show_description && item.enabled && item.description"
              class="content"
            >
              {{ item.description }}
            </p>
            <p
              v-if="
                item.show_description && !item.enabled && item.gray_description
              "
              class="content"
            >
              {{ item.gray_description }}
            </p>
            <p
              v-if="
                item.enabled == 0 &&
                  mall_caculate_info.paymentMethodLimitInfo &&
                  +mall_caculate_info.paymentMethodLimitInfo[item.code] == 0
              "
              class="content"
            >
              {{ showPriceLimitTip }}
            </p>
            <p
              v-if="
                googlePayMethods.includes(item.code) &&
                  !(
                    item.show_description &&
                    (item.description || item.gray_description)
                  ) &&
                  !googleValid
              "
              class="content"
            >
              {{ language.google_pay_invalid }}
            </p>
          </div>
        </slide-up-down>
        <!-- 区域:PayInline -->
        <template
          v-if="
            needPayInlinePaymentMethods &&
              needPayInlinePaymentMethods.indexOf(item.code) > -1
          "
        >
          <div
            v-show="status.cardPay == item.code && showWidgetForm"
            :id="paymentsContainerID"
            style="margin-top: 0.32rem; position: relative; z-index: 1"
          ></div>
        </template>
      </div>
  
      <!-- paypal ga 签约提示按钮 -->
      <i
        v-if="
          paypalGaVault &&
            paypalGaVault.valid &&
            item.enabled == 1 &&
            paymentDisplay &&
            needPayInlinePaymentMethods &&
            needPayInlinePaymentMethods.indexOf(item.code) > -1 &&
            item.code == 'PayPal-GApaypal' &&
            paypalGaVault.visibleArrow
        "
        class="iconfont icon-forward-down32px ppgv-arrow"
        @click.stop="clickPaypalGaType()"
      ></i>

      <HomogenizationLogList
        v-if="isShowHomogenizationLogList"
        :is-disabled-payment="isDisabledPayment"
        :banks-config="banksConfig"
        :is-select-pay="true"
        :language="language"
      />
    </template>
  </li>
</template>

<script>
import { PPGA_CHECK_TYPE, AFTERPAY_CHECK_TYPE } from 'public/src/pages/common/constants'
import { transformImg, template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { googlePayMethods } from 'public/src/pages/checkout/components/config'
import CheckPpgaType from '../vue_tpls/CheckPpgaType.vue'
import CheckAfterPayType from '../vue_tpls/CheckAfterPayType.vue'
import slideUpDown from 'vue-slide-up-down'
import paymentOptionsMixin from './payment_options_mixin.js'
import { payCardPrefetchFn } from './payment_options_item_prefetch'
import PaymentOptionsItemCod from './payment_options_item_cod.vue'
import ItemInfoBnpl from 'public/src/pages/checkout/vue_tpls/payment_list/item_info_bnpl.vue'
import { mapGetters, mapState, mapMutations } from 'vuex'
import { Toast as $toast } from '@shein/sui-mobile'
import { daEventCenter } from 'public/src/services/eventCenter'
import { useTokenBinDiscount } from './token_pay/useTokenBinDiscount.js'
import HomogenizationPay from 'public/src/pages/checkout/page_tpls/pay_homogenization/homogenization_pay.vue'
import HomogenizationLogList from 'public/src/pages/checkout/page_tpls/pay_homogenization/homogenization_logo_list.vue'
import PayBinCoupons from 'public/src/pages/checkout/page_tpls/coupons/pay_bin_coupons.vue'

const { GB_cssRight, lang } = gbCommonInfo
export default {
  name: 'PaymentOptionsItem',
  // 组件注册
  components: {
    slideUpDown,
    CheckPpgaType,
    CheckAfterPayType,
    PaymentOptionsItemCod,
    ItemInfoBnpl,
    Icon,
    HomogenizationPay,
    HomogenizationLogList,
    PayBinCoupons,
  },
  mixins: [paymentOptionsMixin],
  // 组件传参
  props: {
    productType: {
      type: String,
      default: 'normal'
    },
    item: {
      type: [Object, Boolean],
      default: () => {}
    },
    ppgvAccountInfo: {
      type: Object,
      default: () => {}
    },
    afterpayAccountInfo: {
      type: Object,
      default: () => {}
    },
    isShowViewMore: {
      type: Boolean,
      default: false
    },
    idx: {
      type: Number,
      default: 0
    },
    checkout: {
      type: Object,
      default: () => {}
    },
    status: {
      type: Object,
      default: () => {}
    },
    notSupportCodReason: {
      type: String,
      default: ''
    },
    banksConfig: {
      type: Object,
      default: () => {}
    },
    paymentDisplayAgain: {
      type: Function,
      default: null
    },
    paypalGaVault: {
      type: Object,
      default: () => {}
    },
    afterPayVault: {
      type: Object,
      default: () => ({})
    },
    language: {
      type: Object,
      default: () => {}
    },
    paymentIconPrefix: {
      type: String,
      default: ''
    },
    googleValid: {
      type: Boolean,
      default: false
    },
    price: {
      type: Object,
      default: () => {}
    },
    installmentInfo: {
      type: Object,
      default: () => {}
    },
    paymentOption: {
      type: Object,
      default: () => {}
    },
    cardBinDiscountDesc: {
      type: String,
      default: ''
    },
    needPayInlinePaymentMethods: {
      type: Array,
      default: () => []
    },
    codDisplay: {
      type: [Boolean, Number, String],
      default: ''
    },
    showWidgetForm: {
      type: [Boolean, Number],
      default: false
    },
    locals: {
      type: Object,
      default: () => {}
    },
    isPayMethodsDrawer: {
      type: Boolean,
      default: false
    },
    defaultSelectTokenPay: {
      type: Object,
      default: () => {}
    },
    isAutoRenewal: {
      type: Boolean,
      default: false
    },
    isHomogenizationPay: { // 是否是同质化支付方式列表
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      PPGA_CHECK_TYPE,
      AFTERPAY_CHECK_TYPE,
      GB_cssRight,
      googlePayMethods,
      paytmUPIDTImer: null,
      lang,
      panlePaymentOption: {
        'Paytm-UPI': {
          payerAccount: ''
        },
        'cashfree-upi': {
          payerAccount: ''
        }
      },
      tokenBinDiscountDesc: '',
      nowDiscountType: 0
    }
  },
  
  // 计算属性
  computed: {
    ...mapState('checkout', ['cardBinDiscountInfo', 'handlerSelectBin', 'usableCouponList', 'coupon', 'installmentSelectBin', 'installmentSelectToken', 'isUseNewCard']),
    ...mapGetters('checkout', ['productAutoInfo', 'xtraAutoController']),
    selectedAfterpay () {
      return this.cardValue && 
        this.item.code === 'afterpay-card'
    },
    selectedPaypal () {
      return this.cardValue && 
        this.item.code === 'PayPal-GApaypal'
    },
    itemCode() {
      return this.item.code?.toLowerCase() || ''
    },
    isClient() {
      return typeof window != 'undefined'
    },
    cardValue() {
      return !!(this?.status?.cardPay == this?.item?.code && this?.item?.enabled == 1)
    },
    mall_caculate_info () {
      return this.checkout.mall_caculate_info || this.checkout.caculate_info || {}
    },
    paymentItemAnasysis() {
      let is_vaulting = '-'
      if (this.item.code == 'PayPal-GApaypal') {
        is_vaulting = !!this.ppgvAccountInfo?.signUpEmail
          ? PPGA_CHECK_TYPE.CHECKED
          : PPGA_CHECK_TYPE.UNCHECKED
      }
      const { folded_position, originFoldedPosition } =
        this.checkout.results || {}
      // is_folded：是否折叠 若加载结算页后默认展示的支付方式被曝光，则上报0
      // 若是点击「View More」之后才被曝光的支付方式，则上报1。
      const is_folded = (folded_position == -1 && this.idx >= originFoldedPosition) ? 1 : 0
      // 0：无支付方式优惠; 1：有支付方式优惠
      const is_pay_promotion = this.onlinePayDiscountInfoValue ? 1 : 0
      // 0:没有默认选中;1:默认选中
      const is_default = this.cardValue && this.item?.last_payment ? 1 : 0
      // 0：无默认选中埋;1: 接口返回;2：cache 缓存
      const default_type = this.item?.last_payment ? 1 : 0
      // 展示顺序
      const sequence = this.idx + 1
      return { payment_method: this.item?.code, is_vaulting, is_folded, is_pay_promotion, is_default, default_type, sequence }
    },
    paymentDisplay() {
      if (
        this.paymentDisplayAgain &&
        typeof this.paymentDisplayAgain == 'function'
      ) {
        return this.paymentDisplayAgain(this.item.code)
      } else {
        return true
      }
    },
    isDisabledPayment() {
      return this.item.enabled == 0 || !this.paymentDisplay
    },
    getPaymentLogoUrl() {
      if (this.itemCode == 'cod') {
        return !this.notSupportCodReason && this.item.enabled == 1
          ? this.item.logo_url
          : this.item.gray_logo_url
      }

      if (this.isShowTokenPayment && this.nowShowTokenPayment) {
        return this.nowShowTokenPayment?.app_logo
      }

      if (this.isShowTokenFrontInstallment && this.nowInstallmentTokenPayment) {
        if (this.isUseNewCard) {
          return 'https://img.ltwebstatic.com/images3_ccc/2024/06/14/aa/1718352340ff2421c5efcedb6a17bb10bd997ca733.png'
        }
        return this.nowInstallmentTokenPayment?.app_logo
      }

      if (!this.isDisabledPayment) {
        return (
          this.banksConfig?.[this.item.code]?.bankLogo || this.item.logo_url
        )
      }
      return this.item.gray_logo_url
    },
    getPaymentTitle() {
      if (this.itemCode == 'cod') {
        return this.item.title
      } 
      if (this.item.code == 'PayPal-GApaypal' && this.paypalGaVault?.valid) {
        if (this.paypalGaVault?.express) {
          return this.language.SHEIN_KEY_PWA_25526
        } else if (this.paypalGaVault?.type == PPGA_CHECK_TYPE.CHECKED) {
          return (
            this.ppgvAccountInfo?.signUpEmail ||
            this.language.SHEIN_KEY_PWA_19684
          )
        }
      }
      if (this.item.code == 'afterpay-card' && this.afterPayVault?.title) {
        if(this.afterPayVault?.title === 'A') {
          return this.language?.SHEIN_KEY_PWA_30872
        } else if (this.afterPayVault?.title === 'B') {
          return this.language?.SHEIN_KEY_PWA_30873
        }
      }

      if (this.isShowTokenPayment && this.nowShowTokenPayment) {
        return this.nowShowTokenPayment?.card_no
      }

      if (this.isShowTokenFrontInstallment && this.nowInstallmentTokenPayment) {
        if (this.isUseNewCard) {
          return this.language.SHEIN_KEY_PWA_30049
        }
        return `${this.nowInstallmentTokenPayment?.card_no}${this.language.SHEIN_KEY_PWA_31887}`
      }

      return (
        (this.item?.bank_list?.length &&
          this.banksConfig?.[this.item.code]?.bankCodeText) ||
        (this.item.show_title && this.item.title) ||
        ''
      )
    },
    paymentMethodLimitInfo() {
      return this.mall_caculate_info?.paymentMethodLimitInfo || {}
    },
    // 置灰文案
    paymentDes() {
      return this.getPaymentDes(this.item)
    },
    showPriceLimitTip() {
      return this.showPriceLimit(this.item.code)
    },
    onlinePayDiscountInfoValue() {
      return (
        this.price?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[
          this.item?.code
        ] || null
      )
    },
    onlinePayDiscountInfoMinAmount() {
      return +this.onlinePayDiscountInfoValue?.min?.amount
    },
    frontListItemsValue() {
      return this.installmentInfo?.front_list_items?.[this.item?.code] || {}
    },
    showInstallmentInfo(){
      return this.item?.enabled == 1 && this.frontListItemsValue?.is_display == 1 
    },
    showDescriptionTxt2() {
      let item = this.item
      let status =
        ((this.notSupportCodReason && this.itemCode == 'cod') ||
          (item.show_description && item.enabled && item.description) ||
          (item.show_description && !item.enabled && item.gray_description) ||
          (this.itemCode != 'cod' &&
            item.enabled == 0 &&
            +this.mall_caculate_info?.paymentMethodLimitInfo?.[item?.code] ==
              0) ||
          (this.googlePayMethods.includes(item.code) &&
            !(
              item.show_description &&
              (item.description || item.gray_description)
            ) &&
            !this.googleValid)) &&
        this.status.cardPay == item.code
      return status
    },
    tokenBinDiscountFrontWebAbt() {
      return this.checkout?.abtInfo?.tokenBinDiscountFrontWeb?.param?.ableWeb == 1
    },

    // 是否展示卡 bin 优惠
    isShowTokenBinDiscount() {
      if (!this.tokenBinDiscountFrontWebAbt) {
        return false
      }

      if (this.isShowTokenPayment && this.nowShowTokenPayment) {
        const { all_card_bin_discount_map = {} } = this.cardBinDiscountInfo
        return (this.nowShowTokenPayment?.card_bin in all_card_bin_discount_map)
      }

      if (this.isShowTokenFrontInstallment && this.nowInstallmentTokenPayment) {
        const { all_card_bin_discount_map = {} } = this.cardBinDiscountInfo
        return (this.nowInstallmentTokenPayment?.card_bin in all_card_bin_discount_map)
      }

      return false
    },

    isShowCardBinDiscount() {
      if (this.checkout?.results?.bin_discount_list?.length > 1) {
        let is_discount_type = false
        let is_match_payments = this.checkout?.results?.bin_discount_list
          .map(binDiscountItem => {
            const { discount_type = 0, match_payments = [] } =
              binDiscountItem || {}
            const matchPayment = (match_payments || []).some(
              v => v === this.item.code
            )
            if (discount_type != 0) {
              is_discount_type = true
            }
            return matchPayment
          })
          .some(isMatchPayment => isMatchPayment)
        return (
          this.item.enabled &&
          is_discount_type &&
          is_match_payments &&
          this.isShowCardBinDiscountABT
        )
      }

      const { discount_type = 0, match_payments = [] } =
        this.checkout?.results?.bin_discount_list?.[0] ||
        this.checkout?.results?.bin_discount_info ||
        {}
      const matchPayment = (match_payments || []).some(
        v => v === this.item.code
      )
      return (
        this.item.enabled &&
        discount_type &&
        matchPayment &&
        this.isShowCardBinDiscountABT &&
        // 如果同时存在在线支付优惠随机立减和卡bin随机立减，只展示在线支付优惠随机立减
        (discount_type == 3
          ? !this.isShowOnlineRandomDiscount && !!this.cardBinDiscountDesc
          : true)
      )
    },
    // 有门槛/最高折扣金额展示/随机立减（有门槛或0门槛）
    isShowOnlinePayDiscount() {
      const onlinePayDiscountValue = this.onlinePayDiscountInfoValue || {}
      return (
        this.item.enabled &&
        !!Object.keys(onlinePayDiscountValue)?.length &&
        ((onlinePayDiscountValue.discountType != 2 &&
          onlinePayDiscountValue?.min &&
          onlinePayDiscountValue?.min?.amount != 0) ||
          (onlinePayDiscountValue.discountType == 1 &&
            onlinePayDiscountValue?.limit_value &&
            onlinePayDiscountValue?.limit_value?.amount != 0) ||
          this.isShowOnlineRandomDiscount)
      )
    },
    // 随机立减abt
    randomDiscountAbt() {
      return (
        this.checkout?.abtInfo?.PaymentRandomDiscount?.param
          ?.PaymentRandomShow == 'Show'
      )
    },
    // 在线支付优惠 - 随机立减文案
    randomDiscountDes() {
      const onlinePayDiscountValue = this.onlinePayDiscountInfoValue || {}
      if (!+onlinePayDiscountValue?.min?.amount) {
        return this.template(
          onlinePayDiscountValue?.random_min?.amountWithSymbol,
          onlinePayDiscountValue?.random_max?.amountWithSymbol,
          this.language.SHEIN_KEY_PWA_24866
        )
      }
      return this.template(
        onlinePayDiscountValue?.random_min?.amountWithSymbol,
        onlinePayDiscountValue?.random_max?.amountWithSymbol,
        onlinePayDiscountValue?.min?.amountWithSymbol,
        this.language.SHEIN_KEY_PWA_24937
      )
    },
    // 当前支付方式是否展示在线支付随机立减优惠
    isShowOnlineRandomDiscount() {
      const onlinePayDiscountValue = this.onlinePayDiscountInfoValue || {}
      return (
        onlinePayDiscountValue.discountType == 2 &&
        +onlinePayDiscountValue?.random_max?.amount != 0 &&
        this.randomDiscountAbt
      )
    },
    isShowCardBinDiscountABT() {
      const { BinDiscountdiscount = {} } = this.checkout?.abtInfo || {}
      return BinDiscountdiscount?.param?.BinDiscountShow === 'Show'
    },
    // 卡bin随机立减展示
    isShowBinRandomDiscountABT() {
      const { BinRandomDiscount = {} } = this.checkout?.abtInfo || {}
      return BinRandomDiscount?.param?.BinRandomShow === 'Show'
    },
    IS_RW() {
      return this.locals?.IS_RW || gbCommonInfo.IS_RW
    },
    calcSaIdExposePayment() {
      if (this.productType === 'giftcard') {
        return '1-55-1-3'
      }
      return '1-11-1-129'
    },
    paymentsContainerID() {
      if (this.isPayMethodsDrawer) {
        return this.item.code + '-dialog-payments-container'
      }
      return this.item.code + '-payments-container'
    },

    // abt 是否展示 token 支付
    isShowTokenPaymentByAbt() {
      const { webshowTokenFront = {} } = this.checkout?.abtInfo || {}
      return webshowTokenFront?.param?.webshowTokenFrontStatus === '1'  // 1: 展示token前置功能
    },

    // 展示卡分期token前置功能
    isShowTokenFrontInstallmentAbt() {
      const { showTokenFrontInstallment = {} } = this.checkout?.abtInfo || {}
      return showTokenFrontInstallment?.param?.showTokenFrontInstallmentStatus === '1'   
    },

    // abt 是否展示afterpay-cards的记住token
    isShowAfterPayRememberByAbt() {
      return true
      // const { afterpaytoken = {} } = this.checkout?.abtInfo || {}
      // return afterpaytoken?.param?.afterpaytokenstatus === '1'  // 1: 展示Remember my afterpay account details
    },

    isShowTokenPayment() {
      // 后端字段是否展示 token 支付
      const isShowTokenPaymentByBackEnd = this.item?.code === 'routepay-card' && this.item?.shieldAddCard === 0 // 0代表b不屏蔽，则走abt的判断逻辑
      // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
      return isShowTokenPaymentByBackEnd && this.isShowTokenPaymentByAbt && (this.item?.card_token_list || [])?.length > 0 
    },

    installmentHadTokenData() {
      return this.item?.code === 'routepay-cardinstallment' && (this.item?.card_token_list || [])?.length > 0 
    },

    isShowTokenFrontInstallment() {
      // 后端字段是否展示 token 支付
      const isShowTokenPaymentByBackEnd = this.item?.code === 'routepay-cardinstallment'

      // 若token数据为空，则判断不符合token前置的场景，则无新增逻辑
      return isShowTokenPaymentByBackEnd && this.isShowTokenFrontInstallmentAbt && (this.item?.card_token_list || [])?.length > 0 
    },

    nowShowTokenPayment() {
      if (!this.isShowTokenPayment) return {}
      if (!this.handlerSelectBin && Object.keys(this.defaultSelectTokenPay || {})?.length > 0) {
        return this.defaultSelectTokenPay
      }
      return (this.item?.card_token_list || [])?.find(item => item.id === this.defaultSelectTokenPay?.id)
    },

    nowInstallmentTokenPayment() {
      if (!this.isShowTokenFrontInstallment || !this.item?.card_token_list) return {}
      return (this.item?.card_token_list || [])?.find(item => item.card_bin === this.installmentSelectBin)
    },

    isHadShowOnlinePayDiscount() {
      return this.isShowOnlinePayDiscount &&
              this.onlinePayDiscountInfoValue &&
              this.onlinePayDiscountInfoValue.discountType != 2
    },

    isShowRandomDiscount() {
      return this.onlinePayDiscountInfoValue &&
              this.onlinePayDiscountInfoValue.discountType == 2 &&
              this.randomDiscountAbt &&
              this.item.enabled
    },

    // 是否展示右侧提示问号
    isShowCheckTips() {
      if (this.isShowTokenPayment) return false
      return !(this.status?.cardPay == this.item?.code)
    },

    isShowMoreDiscount() {
      const { all_card_bin_discount_map = {} } = this.cardBinDiscountInfo
      if (Object.keys(all_card_bin_discount_map).length === 0) {
        return false
      }

      const tempBinList = (this.item?.card_token_list || [])?.map(item => item?.card_bin) || []
      const allCardBinDiscountList = (Object.keys(all_card_bin_discount_map) || []).some(item => tempBinList.includes(item))
      if (!allCardBinDiscountList) {
        return false
      }

      const cardBin = this.item?.code === 'routepay-card' ? this.nowShowTokenPayment?.card_bin : this.installmentSelectBin
      return !(cardBin in all_card_bin_discount_map)
    },

    isShowTokenBinDiscountBlock() {
      return this.isShowTokenBinDiscount && this.tokenBinDiscountDesc?.length > 0
    },

    isShowZeroDiscount() {
      return this.item.enabled &&
        this.onlinePayDiscountInfoValue &&
        ((this.itemCode != 'cod' && this.onlinePayDiscountInfoMinAmount == 0) ||
          this.itemCode == 'cod') && [0, 1].includes(this.onlinePayDiscountInfoValue?.discountType)
    },

    homogenization() {
      return this?.checkout?.results?.homogenization || {}
    },

    isPaymentHomogenizationShow() {
      return this?.checkout?.results?.isPaymentHomogenizationShow
    },

    // 目前选中了同质化支付方式
    isHadSelectHomogenizationPay() {
      const { sub_cate_code_list = [] } = this.homogenization
      return sub_cate_code_list?.includes(this.status?.cardPay)
    },

    isSelectHomogenizationPay() {
      const { sub_cate_code_list = [] } = this.homogenization
      return this.isPaymentHomogenizationShow && sub_cate_code_list?.includes(this.item?.code)
    },

    // 是否展示为同质化入口
    isFirstPaymentHomogenizationCode() {
      if (this.isHomogenizationPay || this.isHadSelectHomogenizationPay) return false // 同质化弹窗不展示同质化入口

      const { firstPaymentHomogenizationCode = '' } = this.homogenization
      return this.isPaymentHomogenizationShow && firstPaymentHomogenizationCode === this.item?.code
    },

    isNoCodPayShow() {
      return (['cod'].indexOf(this.itemCode) < 0 && this.item.is_display)
    },

    isShowHomogenizationLogList() {
      return this.isShowThisPay && this.isSelectHomogenizationPay && !this.isHomogenizationPay
    },

    // 是否展示该支付方式
    isShowThisPay() {
      if (this.status?.cardPay == this.item?.code || this.itemCode == 'cod' && this.codDisplay) return true

      if (this.isPaymentHomogenizationShow) {
        if (this.isHomogenizationPay) return this.isNoCodPayShow // 同质化弹窗展示所有支付方式
        // 过滤同质化支付方式
        const { sub_cate_code_list = [] } = this.homogenization
        return (!sub_cate_code_list?.includes(this.item?.code) || this.isFirstPaymentHomogenizationCode) && this.isNoCodPayShow
      }

      return this.isNoCodPayShow
    },
    defaultSelectTokenPayCardBin() {
      return this.defaultSelectTokenPay?.card_bin || ''
    },

    cardBin() {
      return this.handlerSelectBin || this.defaultSelectTokenPayCardBin || ''
    },    

    supportBinData() {
      // token支付情况下，展示券逻辑
      const allCanUseUsableCouponList =
        this.usableCouponList?.filter(item =>
          item?.card?.card_codes?.includes(this.cardBin)
        ) || []

      const nowUseCoupon = allCanUseUsableCouponList?.find(item =>
        this.coupon?.applyCoupons?.includes(item?.coupon)
      )

      // 已若卡bin优惠券券已被使用（计算价格使用了券码），则正常下发展示使用成功的卡bin优惠券信息
      if (nowUseCoupon) {
        return nowUseCoupon
      }

      // 若存在交集的券数量＞1，则优先取接口返回的第一个券
      return allCanUseUsableCouponList?.[0]
    },
    isShowPayBinCoupons() {
      return !!this.supportBinData
    },
    isPayBinCouponsAdd() {
      return this.isShowTokenBinDiscountBlock || this.isHadShowOnlinePayDiscount || this.isShowRandomDiscount 
      || !!(
        !this.isShowTokenPayment && this.isShowCardBinDiscount && this.cardBinDiscountDesc
      )
    },
    isShowRightDrawerIcon() {
      return (this.isShowTokenPayment || this.isShowTokenFrontInstallment) && !this.isPayMethodsDrawer
    },

    isInstallmentIsUseNewCard() {
      if (this?.item?.code !== 'routepay-cardinstallment') return false
      return this.isUseNewCard
    }
  },
  watch: {
    cardValue: {
      immediate: true,
      handler(newValue){
        if (newValue === true) {
          payCardPrefetchFn(this?.item?.payment_type)
        }
      }
    },

    'item.card_token_list': {
      immediate: true,
      deep: true,
      handler(newValue){
        if (this.item?.code === 'routepay-card') {
          const isHadDefaultSelectTokenPay = (this.item?.card_token_list || [])?.some(item => item.id === this.defaultSelectTokenPay?.id)
          if (Object.keys(this.defaultSelectTokenPay || {})?.length > 0 && isHadDefaultSelectTokenPay) return
          if (newValue) {
            const nowData = (this.item?.card_token_list || [])?.find(item => item?.default_token === 1) || []
            this.$emit('changeDefaultSelectTokenPay', nowData)
          } 
        }


        if (this?.item?.code === 'routepay-cardinstallment') {
          const isHadDefaultSelectTokenPay = (this.item?.card_token_list || [])?.some(item => item.card_bin === this.installmentSelectBin)
          if (Object.keys(this.installmentSelectToken || {})?.length > 0 && isHadDefaultSelectTokenPay) return
          if (newValue) {
            const nowData = (this.item?.card_token_list || [])?.find(item => item?.default_token === 1) || {}
            this?.$store && this.assignState({
              installmentSelectToken: nowData,
              installmentSelectBin: nowData?.cardBin || nowData?.card_bin
            })
          }
        }
      }
    },

    'isShowTokenPayment': {
      handler(val) {
        if (this.item?.code === 'routepay-card') {
          this.$store && this.assignState({
            isShowTokenPay: val
          })
          daEventCenter.triggerNotice({
            daId: '1-11-1-172',
            extraData: {
              scenes: 'front_token',
              type: val ? 1 : 0
            }
          })
        }
      },
      immediate: true
    },

    isShowTokenBinDiscount: {
      handler(val) {
        if (val) {
          const cardBin = (this.item?.code === 'routepay-card') ? this.nowShowTokenPayment?.card_bin : this.installmentSelectBin
          const { getCardBinDiscountDesc, type } = useTokenBinDiscount(this.cardBinDiscountInfo, cardBin, this.language)
          this.tokenBinDiscountDesc = getCardBinDiscountDesc()
          this.nowDiscountType = type
        }
      },
      immediate: true
    },

    'nowShowTokenPayment.card_bin': {
      handler(val) {
        if (val) {
          const { getCardBinDiscountDesc, type } = useTokenBinDiscount(this.cardBinDiscountInfo, this.nowShowTokenPayment?.card_bin, this.language)
          this.tokenBinDiscountDesc = getCardBinDiscountDesc()
          this.nowDiscountType = type
        }
      }
    },

    'installmentSelectBin': {
      handler(val) {
        if (val) {
          const { getCardBinDiscountDesc } = useTokenBinDiscount(this.cardBinDiscountInfo, this.installmentSelectBin, this.language)
          this.tokenBinDiscountDesc = getCardBinDiscountDesc()
        }
      }
    },

    isHadOtherDiscount() {
      return 
    },

    isAutoRenewal: {
      immediate: true,
      handler(val) {
        if (val) {
          this.afterPayVault.radioType = AFTERPAY_CHECK_TYPE.CHECKED
          this.afterPayVault.type = AFTERPAY_CHECK_TYPE.CHECKED
        }
      }
    },

    isShowTokenBinDiscountBlock: {
      handler(val) {
        if (this.item?.code === 'routepay-card') {
          this.$store && this.assignState({
            isShowTokenBinDiscountBlock: val,
            tokenBinDiscountType: this.nowDiscountType
          })
        }
        const changeData = val ? this.nowShowTokenPayment?.card_bin : ''
        this.$store && this.updateCreateOrderBin(changeData)
      },
      immediate: true
    },
    tokenBinDiscountDesc: {
      handler(val) {
        if (this.item?.code === 'routepay-card') {
          this.$store && this.assignState({
            tokenBinDiscountDesc: val,
            tokenBinDiscountType: this.nowDiscountType
          })
        }
      },
      immediate: true
    },

    isShowTokenFrontInstallment: {
      handler(val) {
        if (this.item?.code === 'routepay-cardinstallment') {
          this.$store && this.assignState({
            isShowTokenFrontInstallment: val
          })
        }
      },
      immediate: true
    },
  },

  mounted() {
    if (this?.item?.code === 'routepay-cardinstallment') {
      daEventCenter.triggerNotice({
        daId: '1-11-1-172',
        extraData: {
          scenes: 'installmentToken',
          type: (this.item?.card_token_list || [])?.length > 0 ? 1 : 0
        }
      })
    }
  },

  // 方法实现
  methods: {
    ...mapMutations('checkout', ['updateCreateOrderBin', 'assignState', 'changeHomogenizationDrawerVisible']),
    transformImg,
    template,
    choosePayment(opt) {
      if (this.isFirstPaymentHomogenizationCode) {
        this.changeHomogenizationDrawerVisible(true)
        return
      }
      if (this.item?.code === 'routepay-card' && this.isShowTokenPayment) {
        this.$store && this.assignState({
          handlerSelectBin: this.nowShowTokenPayment?.card_bin || ''
        })
      }

      // 相同item不需要，重新计价
      if(this.status?.cardPay !== this.item?.code){
        this.$emit('choosePayment', opt)
      }
      // 统一交互逻辑，有banklist 点击主动弹框， todo 计价结束回调弹banklist
      if(!this.isDisabledPayment && this.banksConfig[this.item.code] && this.banksConfig[this.item.code]?.list?.length && !this.isShowHomogenizationLogList){
        this.$nextTick(()=>{
          this.$emit('startSelectBank', this.item.code)
        })
      }
    },
    showMaskTips(tip) {
      this.$emit('showMaskTips', tip)
    },
    handleStartSelectBank() {
      this.$emit('startSelectBank', this.item.code)
    },
    handleTogglePpgaType() {
      // 1. 当前有续费包 2. 当前选中的是paypal
      const orderActivity = this?.checkout?.results?.primeActivityType == 1
      const { limitedPayTypeList: limitedPayTypeListPrime, currentProduct, currentProductCycleDays } = this.productAutoInfo || {}
      const { currentXtraIsAuto, xtraCurrentAutoPayLimit } = this.xtraAutoController || {}

      const currentPrimeisAuto = currentProduct?.product_activity_type == 1 && currentProductCycleDays ? true : false
      // 1.成单提示 2.用户同时选择了会员续费产品包和超省卡续费产品包提示 3.区分付费会员或者超省卡提示 
      let tostLanguage = ''
      if(orderActivity){
        tostLanguage = this.language?.SHEIN_KEY_PWA_31368 
      } else {
        if(currentXtraIsAuto && currentPrimeisAuto){
          tostLanguage = this.language?.SHEIN_KEY_PWA_31368
        } else {
          tostLanguage = currentXtraIsAuto ? this.language?.SHEIN_KEY_PWA_31368 : this.template(currentProduct?.product_name_language, this.language?.SHEIN_KEY_PWA_29576)
        }
      }
      const paymentLimitIncludes = limitedPayTypeListPrime?.some(s => s.code === window?.shem_caculate_data?.payment_code_unique) || xtraCurrentAutoPayLimit
      const currentIsAfterPay = window?.shem_caculate_data?.payment_code_unique == 'PayPal-GApaypal'
      const isProdctAuto = currentPrimeisAuto || currentXtraIsAuto
      // 当前支付是afterpay-card && (paymentLimitIncludes && isProdctAuto)当前支付在快捷里且当前有产品包是自动续费 || orderActivity成单或者随单 && 选中状态
      if((currentIsAfterPay && paymentLimitIncludes && isProdctAuto) || orderActivity && this.paypalGaVault?.type == 1){
        return $toast( tostLanguage )
      }
      this.$emit('handleTogglePpgaType')
    },
    handleToggleAfterPayType() {
      // 1. 当前有续费包 2. 当前选中的是afterPay
      const orderActivity = this?.checkout?.results?.primeActivityType == 1
      const { limitedPayTypeList: limitedPayTypeListPrime, currentProduct, currentProductCycleDays } = this.productAutoInfo || {}
      const { currentXtraIsAuto, xtraCurrentAutoPayLimit } = this.xtraAutoController || {}

      const currentPrimeisAuto = currentProduct?.product_activity_type == 1 && currentProductCycleDays ? true : false
      // 1.成单提示 2.用户同时选择了会员续费产品包和超省卡续费产品包提示 3.区分付费会员或者超省卡提示 
      let tostLanguage = ''
      if(orderActivity){
        tostLanguage = this.language?.SHEIN_KEY_PWA_31368 
      } else {
        if(currentXtraIsAuto && currentPrimeisAuto){
          tostLanguage = this.language?.SHEIN_KEY_PWA_31368
        } else {
          tostLanguage = currentXtraIsAuto ? this.language?.SHEIN_KEY_PWA_31368 : this.template(currentProduct?.product_name_language, this.language?.SHEIN_KEY_PWA_29576)
        }
      }
      const paymentLimitIncludes = limitedPayTypeListPrime?.some(s => s.code === window?.shem_caculate_data?.payment_code_unique) || xtraCurrentAutoPayLimit
      const currentIsAfterPay = window?.shem_caculate_data?.payment_code_unique == 'afterpay-card'
      const isProdctAuto = currentPrimeisAuto || currentXtraIsAuto
      // 当前支付是afterpay-card && (paymentLimitIncludes && isProdctAuto)当前支付在快捷里且当前有产品包是自动续费 || orderActivity成单或者随单 && 选中状态
      if((currentIsAfterPay && paymentLimitIncludes && isProdctAuto) || orderActivity && this.afterPayVault?.type == 1){
        return $toast( tostLanguage )
      }

      this.$emit('handleToggleAfterPayType')
    },
    checkValidatePaytmUPI(payment_code) {
      const payerAccount = this.panlePaymentOption[payment_code].payerAccount
      if (this.paytmUPIDTImer) clearTimeout(this.paytmUPIDTImer)
      this.paytmUPIDTImer = setTimeout(() => {
        if (payerAccount == '' || payerAccount.length > 32) {
          this.paymentOption[payment_code].payerAccount = payerAccount
          this.paymentOption[payment_code].showError = true
          return
        }
        this.paymentOption[payment_code].payerAccount = payerAccount
        this.paymentOption[payment_code].showError = false
      }, 200)
    },
    handlePaymentOptionsOper(type) {
      this.$emit('handlePaymentOptionsOper', type)
    },
    clickDes() {
      this.paymentDes && this.showMaskTips(this.paymentDes)
    },
    //展示paypal ga 签约切换弹窗
    clickPaypalGaType() {
      this.$emit('clickPaypalGaType')
    },
    paypalExposeData(paymentItem = {}) {
      if (paymentItem?.code !== 'PayPal-GApaypal') return {}
      return {
        id: '2-31-8',
        data: {
          payment_method: paymentItem.code,
          is_paypal: this.paypalGaVault?.valid ? '1' : '0',
          is_vaulting_account: !!this.ppgvAccountInfo?.signUpEmail ? '1' : '0',
          is_vaulting_status: this.paypalGaVault?.valid ? '1' : '0'
        }
      }
    },

    handlerTokenList() {
      if (this.item.enabled === 0) return
      if (this.isShowTokenFrontInstallment) {
        this.$store && this.assignState({
          isShowInstallmentTokenDrawer: true
        })
      } else {
        this.$emit('showTokenListDrawer')
      }
    },
    codTipExpose() {
      daEventCenter.triggerNotice({
        daId: '2-31-10-3',
      })
    },
    clickCodTips() {
      daEventCenter.triggerNotice({
        daId: '2-31-10-4',
      })
    }
  }
}
</script>
