<template>
  <div class="discount-tips-block">
    <!-- 卡bin优惠券 -->
    <pay-bin-coupons 
      v-if="isRouterPay && !isHomogenization"
      class="discount-tips__coupons_txt"
      scene="discount-tips"
      :card-bin="nowShowTokenPaymentCardBin"
      :is-pay-bin-coupons-add="isPayBinCouponsAdd" 
      :is-show-token-payment="isShowTokenPayment"
      :language="language"
    />

    <!-- token 卡 bin 优惠 -->
    <div
      v-if="isShowTokenBinDiscountBlock && (isRouterPay || isInstallmentPay) && !isHomogenization && !isInstallmentIsUseNewCard"
      class="bin-discount-block"
    >
      <span class="discount-tips-item bin-desc">
        {{ tokenBinDiscountDesc }}
      </span>
      <span
        v-if="isShowRandomDiscountDes || isShowOnlinePayDiscount"
        class="add-tip"
      >
        +
      </span>
    </div>

    <div class="discount-percent_tips discount-percent-sy">
      <div
        v-if="
          isShowOnlinePayDiscount &&
            onlinePayDiscountInfoValue &&
            onlinePayDiscountInfoValue.discountType != 2
        "
        class="discount-tips-item"
      >
        <div
          v-if="onlinePayDiscountInfoMinAmount != 0"
          class="payment-discount-tip"
          :style="{ color: IS_RW && '#FC4070' }"
        >
          <!-- 满减优惠 -->
          <template v-if="onlinePayDiscountInfoValue.discountType == 0">
            {{
              template(
                onlinePayDiscountInfoValue.min.amountWithSymbol,
                onlinePayDiscountInfoValue.amount.amountWithSymbol,
                language.SHEIN_KEY_PWA_16207
              )
            }}
          </template>
          <!-- 折扣优惠 -->
          <template v-if="onlinePayDiscountInfoValue.discountType == 1">
            {{
              template(
                onlinePayDiscountInfoValue.min.amountWithSymbol,
                onlinePayDiscountInfoValue.percent + '%OFF',
                language.SHEIN_KEY_PWA_16207
              )
            }}.
          </template>
          <!-- 最大优惠信息 -->
          <template
            v-if="
              onlinePayDiscountInfoValue.discountType == 1 &&
                onlinePayDiscountInfoValue.limit_value &&
                +onlinePayDiscountInfoValue.limit_value.amount != 0
            "
          >
            {{
              language.SHEIN_KEY_PWA_19681.replace(
                '{limitValue}',
                onlinePayDiscountInfoValue.limit_value.amountWithSymbol
              )
            }}
          </template>
        </div>
        <div
          v-else
          class="payment-discount-tip"
          :style="{ color: IS_RW && '#FC4070' }"
        >
          <!-- 最大优惠信息 -->
          <template
            v-if="
              onlinePayDiscountInfoValue.discountType == 1 &&
                onlinePayDiscountInfoValue.limit_value &&
                +onlinePayDiscountInfoValue.limit_value.amount != 0
            "
          >
            {{
              language.SHEIN_KEY_PWA_19681.replace(
                '{limitValue}',
                onlinePayDiscountInfoValue.limit_value.amountWithSymbol
              )
            }}
          </template>
        </div>
      </div>

      <!-- 随机立减 -->
      <div
        v-if="isShowRandomDiscountDes"
        class="discount-tips-item"
      >
        <div
          class="payment-discount-tip"
          :style="{ color: IS_RW && '#FC4070' }"
        >
          {{ randomDiscountDes }}
        </div>
      </div>
      <!-- 卡bin优惠信息展示 -->
      <template v-if="!isShowTokenPayment && !isShowTokenFrontInstallment">
        <span
          v-if="
            isShowOnlinePayDiscount &&
              isShowCardBinDiscount &&
              cardBinDiscountDesc
          "
        >
          +
        </span>
        <span
          v-if="isShowCardBinDiscountDesc"
          class="discount-tips-item"
        >
          <span ref="cardBinDiscountDescRef">{{ cardBinDiscountDesc }}</span>
          <i
            ref="cardBinDiscountTipRef"
            class="sui_icon_doubt_16px_2 icon"
            :class="[
              paymentIconPrefix,
              {
                'tip-wrapped': cardBinDiscountTipRefIsWrapped,
                'no-left': isTextWrapped
              }
            ]"
            style="width: 12px"
            @click.stop="handlePaymentOptionsOper('bin-discount-article')"
          ></i>
        </span>
      </template>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import { useDiscount } from './useDiscount'
import { useStore } from 'public/src/pages/checkout/hooks/index.js'
import PayBinCoupons from 'public/src/pages/checkout/page_tpls/coupons/pay_bin_coupons.vue'
import { useIsShowBinDiscountInfo } from 'public/src/pages/checkout/page_tpls/token_pay/useTokenBinDiscount.js'

const props = defineProps({
  checkout: { type: Object, default: () => ({}) },
  price: {
    type: Object,
    default: () => ({})
  },
  currentPayment: {
    type: Object,
    default: () => ({})
  },
  locals: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => {}
  },
  iconPrefix: {
    type: String,
    default: ''
  },
  isShowTokenPaymentByAbt: {
    type: Boolean,
    default: false
  },
  nowShowTokenPayment: {
    type: Object,
    default: () => ({})
  },
  currentPay: { // 传入的想要获取优惠信息的支付方式
    type: Object,
    default: () => ({})
  },
  isShowTokenPayment: {
    type: Boolean,
    default: false
  },
  isHomogenization: {
    type: Boolean,
    default: false
  }
})

const store = useStore()

const cardBinDiscountTipRef = ref(null)
const cardBinDiscountTipRefIsWrapped = ref(null)
const isTextWrapped = ref(false)
const cardBinDiscountDescRef = ref(null)

const emits = defineEmits(['payment-options-oper'])

const {
  onlinePayDiscountInfoValue,
  cardBinDiscountDesc,
  isShowRandomDiscountDes,
  isShowOnlinePayDiscount,
  isShowCardBinDiscount,
  nowShowTokenPayment
} = useDiscount({ 
  language: props.language,
  initialPayment: props.currentPay 
})

const {
  isShowTokenBinDiscountBlock,
  tokenBinDiscountDesc,
  isShowTokenFrontInstallment
} = useIsShowBinDiscountInfo({
  payment: props.currentPay,
  language: props.language
})

const cardPay = computed(() => {
  return store?.state?.checkout?.status?.cardPay
})

const isUseNewCard = computed(() => {
  return store?.state?.checkout?.isUseNewCard
})

const isRouterPay = computed(() => {
  return cardPay.value === 'routepay-card'
})

const isInstallmentPay = computed(() => {
  return cardPay.value === 'routepay-cardinstallment'
})

const onlinePayDiscountInfoMinAmount = computed(() => {
  return +onlinePayDiscountInfoValue.value?.min?.amount
})

const IS_RW = computed(() => {
  return props.locals?.IS_RW || gbCommonInfo.IS_RW
})

// 在线支付优惠 - 随机立减文案
const randomDiscountDes = computed(() => {
  const onlinePayDiscountValue = onlinePayDiscountInfoValue.value || {}
  if (!+onlinePayDiscountValue?.min?.amount) {
    return template(
      onlinePayDiscountValue?.random_min?.amountWithSymbol,
      onlinePayDiscountValue?.random_max?.amountWithSymbol,
      props.language.SHEIN_KEY_PWA_24866
    )
  }
  return template(
    onlinePayDiscountValue?.random_min?.amountWithSymbol,
    onlinePayDiscountValue?.random_max?.amountWithSymbol,
    onlinePayDiscountValue?.min?.amountWithSymbol,
    props.language.SHEIN_KEY_PWA_24937
  )
})

const paymentIconPrefix = computed(() => {
  return props.iconPrefix ? props.iconPrefix : 'suiiconfont'
})

const isShowCardBinDiscountDesc = computed(() => {
  return isShowCardBinDiscount.value && cardBinDiscountDesc.value
})

const isInstallmentIsUseNewCard = computed(() => {
  if (props?.currentPayment?.code !== 'routepay-cardinstallment') return false
  return isUseNewCard.value
})

watch(
  () => cardPay.value,
  () => {
    if (typeof window === 'undefined') return
    if (isShowCardBinDiscountDesc.value) {
      setTimeout(() => {
        setRefIsWrapped()
      }, 0)
    }
  },
  {
    immediate: true,
    deep: true
  }
)

const isPayBinCouponsAdd = computed(() => {
  return (isShowTokenBinDiscountBlock.value && isRouterPay.value) || isShowRandomDiscountDes.value || isShowOnlinePayDiscount.value || !!isShowCardBinDiscountDesc.value
})

const nowShowTokenPaymentCardBin = computed(() => {
  return nowShowTokenPayment.value?.card_bin
})


const handlePaymentOptionsOper = (type = '', data = {}) => {
  if (!type) return
  emits('payment-options-oper', { type, data })
}

const isWrapped = element => {
  // 如果没有前一个兄弟元素，则认为没有换行
  if (!element?.previousElementSibling) {
    return false
  }
  // 获取当前元素及其前一个兄弟元素的顶部位置
  const currentTop = element?.getBoundingClientRect()?.top
  const siblingTop =
    element?.previousElementSibling?.getBoundingClientRect()?.top

  // 如果当前元素的顶部位置大于前一个兄弟元素的顶部位置，则认为发生了换行
  return currentTop > siblingTop
}

function setRefIsWrapped() {
  if (cardBinDiscountTipRef.value) {
    cardBinDiscountTipRefIsWrapped.value = isWrapped(
      cardBinDiscountTipRef.value
    )
  }

  if (cardBinDiscountDescRef.value) {
    isTextWrapped.value = !hasTextWrapped(cardBinDiscountDescRef.value)
  }
}

function hasTextWrapped(container) {
  // scrollHeight 是元素内容高度的一个度量，包含了由于溢出而不可见的部分。
  // clientHeight 是元素的内部高度，包括 padding ，但不包括水平滚动条、边框和外边距。
  return container.scrollHeight > container.clientHeight
}
</script>

<style scoped lang="less">
.discount-tips-block {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .bin-discount-block {
    display: flex;
    align-items: center;
    margin-bottom: 8/75rem;
  }
  .bin-desc {
    display: inline-block;
  }

  .add-tip {
    margin: 0 8/75rem;
  }

  .bin-coupons-block {
    margin-top: unset;
  }
}
.discount-percent-sy {
  margin: unset;
}

.discount-tips-item {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: unset;
}

.payment-discount-tip {
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tip-wrapped {
  display: inline-block;
  margin-top: 4/75rem;
}

.no-left {
  padding-left: unset;
}

.discount-percent_tips {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
</style>
