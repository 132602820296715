import { getCurrentInstance, computed, ref, watch } from 'vue'

export const useStore = () => {
  const vm = getCurrentInstance()
  if (!vm) return console.warn('useStore must be used after setup')
  return vm?.proxy?.$store
}

export function useMapState(mapper, moduleName = 'checkout') {
  const store = useStore()
  const storeState = {}

  mapper.forEach(item => {
    storeState[item] = computed(() => { return store.state[moduleName][item] })
  })

  return storeState
}

export const useMapMutations = (mutationKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    mutationKeys.map(mutation => [
      mutation,
      value => store?.commit(`${moduleName}/${mutation}`, value)
    ])
  )
}

export const useMapGetters = (getterKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    getterKeys.map(getter => [
      getter,
      computed(() => store?.getters[`${moduleName}/${getter}`])
    ])
  )
}

export const useMapActions = (actionKeys, moduleName = 'checkout') => {
  const store = useStore()
  return Object.fromEntries(
    actionKeys.map(action => [
      action,
      value => store.dispatch(`${moduleName}/${action}`, value)
    ])
  )
}

export const useSendSingleMsg = (daEventCenter) => {
  const storeState = useMapState(['mallCartsList'])
  const shoppingBagTypeAbt = useStore().getters['checkout/shoppingBagTypeAbt']
  const sendMsgNum = ref(0)
  watch(storeState.mallCartsList, (res) => {
    // 1. 判断mall购物袋是否只有一个商品
    const cartsItemOne = res.some(item => item?.length == 1)
    const commonRequire = shoppingBagTypeAbt && !gbCommonInfo.IS_RW
    if(commonRequire && sendMsgNum.value == 0){
      if(cartsItemOne){
        // 只有一件商品，且是qs，在库存位不足数量下调取非qs商品数量，不上报
        const { quick_ship, over_quick_ship, product, quantity } = res[0][0]
        const qsStockNo = quick_ship && over_quick_ship != 1 && product?.qs_stock < quantity
        if(qsStockNo) return
        sendMsgNum.value++
        daEventCenter.triggerNotice({
          daId: '1-11-1-213'
        })
      }else{
      // 2. 两个以上，遍历的当前mall下区分qs和非qs，只要只有一个商品，需上报
        // let shoppingBagisOne = false
        // res.forEach(mallCarst => {
        //   const quickShip = []
        //   const noQuickShip = []
        //   mallCarst.forEach(item => {
        //     if(item?.quick_ship && item?.over_quick_ship != 1){
        //       quickShip.push(item)
        //     } else {
        //       noQuickShip.push(item)
        //     }
        //   })
        //   if(quickShip.length == 1 || noQuickShip.length == 1){
        //     shoppingBagisOne = true
        //   }
        // })
        // if(shoppingBagisOne){
        //   sendMsgNum.value++
        //   daEventCenter.triggerNotice({
        //     daId: '1-11-1-213'
        //   })
        // }
      }
    }
  })
}
